import { Link } from 'react-router-dom'
import styles from './Header.module.css'
import logo from '../../images/logo.png'
function Header(){
    return(
    <div className={styles.header}>
        <div className={styles.top}>
            <Link to="/" className={styles.h1link}><img src={logo} alt="" width="220px" height="90px" className={styles.logo}/></Link>
            <nav className={styles.navbar}>
                <ul className={styles.navbarul}> 
                        <li className={styles.limain}>
                                <Link to="/services" className={styles.link}>Электрооборудование</Link>
                                <ul className={styles.catalog}>
                                    <li className={styles.li}><Link to="/catalog/equipment" className={styles.catalogitem}>Светотехника</Link></li>
                                    <li className={styles.li}><Link to="/catalog/equipment" className={styles.catalogitem}>Прокладка кабельных линий</Link></li>
                                    <li className={styles.li}><Link to="/catalog/equipment" className={styles.catalogitem}>Устройство системы молниезащиты</Link></li>
                                    <li className={styles.li}><Link to="/catalog/equipment" className={styles.catalogitem}>Щитовое оборудование</Link></li>
                                </ul>
                        </li>
                    <li className={styles.limain}>
                        <Link to="/catalog" className={styles.link}>Строительные работы</Link>
                        <ul className={styles.catalog}>
                            <li className={styles.li}><Link to="/catalog/pw" className={styles.catalogitem}>Малярные работы</Link></li>
                            <li className={styles.li}><Link to="/catalog/equipment" className={styles.catalogitem}>Гипрочные работы</Link></li>
                            <li className={styles.li}><Link to="/catalog/equipment" className={styles.catalogitem}>Столярные работы</Link></li>
                            <li className={styles.li}><Link to="/catalog/equipment" className={styles.catalogitem}>Плиточные работы</Link></li>
                            <li className={styles.li}><Link to="/catalog/equipment" className={styles.catalogitem}>Бетонные работы по устройству полов</Link></li>
                            <li className={styles.li}><Link to="/catalog/equipment" className={styles.catalogitem}>Монтаж инженерных систем</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/object" className={styles.link}>Объекты</Link></li>
                    <li><Link to="/partners" className={styles.link}>Партнеры</Link></li>
                    <li><Link to="/about" className={styles.link}>О компании</Link></li>
                    <li><Link to="/contacts" className={styles.link}>Контакты</Link></li>
                </ul>
            </nav>
            <div className={styles.npdiv}>
            <p className={styles.np1}>&#9742; +7 999 999 99 99</p>
            <p className={styles.np}>&#9993; mail@mail</p>
            </div>
        </div>
    </div>
    )
}
export default Header