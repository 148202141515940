import styles from './About.module.css'
import l from '../../images/l.jpg'
import sert from '../../images/i.jpeg'
import thx from '../../images/thx.jpg'


function About(){
    return(
        <div className={styles.main}>
            <div className={styles.about}>
                <h2 className={styles.h2}>О компании</h2>
                <p className={styles.text}>Основной профиль деятельности компании - комплексные поставки промышленного электротехнического оборудования ведущих российских  производителей - от простейших электроизделий до сложных комплектных устройств. Полный комплекс строительных работ по отделке помещений</p>
            </div>
            <div className={styles.sertdiv}>
                <h2 className={styles.h2_sert}>Сертификаты</h2>
                <div className={styles.serts}>
                    <img src={sert} alt="" width="250px" height="150px" className={styles.sert}/>
                </div>
            </div>
            <div className={styles.sertdiv}>
                <h2 className={styles.h2_sert}>Лицензии</h2>
                <div className={styles.serts}>
                    <img src={l} alt="" width="150px" height="250px" className={styles.lice}/>
                </div>
            </div>
            <div className={styles.sertdiv}>
                <h2 className={styles.h2_sert}>Благодарности</h2>
                <div className={styles.serts}>
                    <img src={thx} alt="" width="150px" height="250px" className={styles.lice}/>
                </div>
            </div>
        </div>
    )
}
export default About