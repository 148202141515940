import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import SC from '../../images/SC.jpg'
import LSR from '../../images/LSR.jpg'
import VLKRS from '../../images/velikoross-1.png'
import sert from '../../images/i.jpeg'
import lic from '../../images/l.jpg'
import DLPBSTR from '../../images/dalpstr.png'
import img from '../../images/2b7a0791a29f11efa13bb66da8edef57_1.jpeg'
import ll from '../../images/ll.jpeg'
import p1 from '../../images/Дизайн и функциональность.png'
import p2 from '../../images/Бетонная репутация.png'
import p3 from '../../images/Ваше удобство в приоритете.png'
import p4 from '../../images/Железобетонное качество.png'
import p5 from '../../images/Мы - специалисты по ремонту жилья.png'
import p6 from '../../images/Мы строим, вы отдыхаете.png'
import styles from './Midle.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Midle(){
    return(
        <div className={styles.midle}>
            <div className={styles.h1div}>
                <h1 className={styles.h1}>Надежность репутации - наша ценность</h1>
                <img src={img} alt="" width="1300px"  className={styles.mainimg}/>
            </div>
            <div className={styles.h2div}>
                <h2 className={styles.h2}>Почему мы?</h2>
            </div>
            <div className={styles.bio}>
                <div className={styles.biodiv}>
                    <div className={styles.biodivdiv}>
                        <img src={p3} alt="" width="50px" height="50px" className={styles.svg}/>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Ваше удобство в приоритете</h3>
                        </div>
                    </div>
                    <div className={styles.biodivdiv}>
                        <img src={p6} alt="" width="50px" height="50px" className={styles.svg}/>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Мы строим, вы отдыхаете</h3>
                        </div>
                        </div>
                </div>
                <div classNames={styles.biodiv}>
                    <div className={styles.biodivdiv}> 
                        <img src={p1} alt="" width="50px" height="50px" className={styles.svg}/>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Дизайн и функциональность</h3>
                        </div>
                    </div>    
                    <div className={styles.biodivdiv}>      
                        <img src={p2} alt="" width="50px" height="50px" className={styles.svg}/>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Бетонная репутация</h3>
                        </div>
                    </div>  
                </div>
                <div className={styles.biodiv}>
                    <div className={styles.biodivdiv}>
                        <img src={p5} alt="" width="50px" height="50px" className={styles.svg}/>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Мы - специалисты по ремонту жилья</h3>
                        </div>
                    </div>
                    <div className={styles.biodivdiv}>
                        <img src={p4} alt="" width="50px" height="50px" className={styles.svg}/>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Железобетонное качество</h3>
                        </div>
                    </div>
                </div>
            </div>
        <div className={styles.sponsor}>
            <div className={styles.h2div}>
            <h2 className={styles.h2}>С нами работают</h2>
            </div>
            <div className={styles.sponsordiv}>
                <div className={styles.sponsorname}>
                    <img src={DLPBSTR} alt="" width="150px" height="70px" className={styles.img}/>
                    <p className={styles.text}> <h4 className={styles.h4}>Дальпитерстрой</h4>строительная компания</p>
                </div>    
                <div className={styles.sponsorname}>
                    <img src={LSR} alt="" width="150px" height="70px" className={styles.img}/>
                    <p className={styles.text}> <h4 className={styles.h4}>ЛСР</h4>строительная компания</p>
                </div> 
                <div className={styles.sponsorname}>
                    <img src={SC} alt="" width="150px" height="70px" className={styles.img}/>
                    <p className={styles.text}> <h4 className={styles.h4}>Сетл сити</h4>строительная компания</p>
                </div> 
                <div className={styles.sponsorname}>
                    <img src={VLKRS} alt="" width="150px" height="70px" className={styles.img}/>
                    <p className={styles.text}> <h4 className={styles.h4}>Великоросс</h4>фабрика вкусной еды</p>
                </div>
            </div>
        </div>
        <div className={styles.onas}>
            <img src={ll} alt="" width="500px" className={styles.aboutimg}/>
            <div className={styles.about}>
                <h2 className={styles.h2}>О нас</h2>
                    <h3 className={styles.h3}>VectorElectro</h3>
                    <p className={styles.text}>Основной профиль деятельности компании - комплексные поставки промышленного электротехнического оборудования ведущих российских  производителей - от простейших электроизделий до сложных комплектных устройств. Полный комплекс строительных работ по отделке помещений</p>
            </div>
        </div>
            <div className={styles.sert}>
            <div className={styles.h2div}>
                <h2 className={styles.h2}>Сертификаты и Лицензии</h2>
                </div>
                <div className={styles.sponsordiv}>
                    <Swiper
                        modules={[Navigation, A11y]}
                        spaceBetween={10}
                        slidesPerView={3}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div className={styles.sertename}>
                                <img src={sert} alt="" width="200px" height="130px" className={styles.serte}/>
                                <p className={styles.text}> <h4 className={styles.h4}>Сертификат</h4>пустой сертификат</p>
                            </div>  
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.sertename}>
                                <img src={sert} alt="" width="200px" height="130px" className={styles.serte}/>
                                <p className={styles.text}> <h4 className={styles.h4}>Сертификат</h4>пустой сертификат</p>
                            </div>  
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.sertename}>
                                <img src={sert} alt="" width="200px" height="130px" className={styles.serte}/>
                                <p className={styles.text}> <h4 className={styles.h4}>Сертификат</h4>пустой сертификат</p>
                            </div>  
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.sertename}>
                                <img src={lic} alt="" width="120px" height="150px" className={styles.lice}/>
                                <p className={styles.text}> <h4 className={styles.h4}>Лицензия</h4>пустая лицензия</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.sertename}>
                                <img src={lic} alt="" width="120px" height="150px" className={styles.lice}/>
                                <p className={styles.text}> <h4 className={styles.h4}>Лицензия</h4>пустая лицензия</p>
                            </div>
                        </SwiperSlide> 
                        <SwiperSlide>
                            <div className={styles.sertename}>
                                <img src={lic} alt="" width="120px" height="150px" className={styles.lice}/>
                                <p className={styles.text}> <h4 className={styles.h4}>Лицензия</h4>пустая лицензия</p>
                            </div>
                        </SwiperSlide>            
                    </Swiper>
                </div>
                </div>
            </div>  
    )
}
export default Midle